import { ButtonBase, Grid } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import Image from 'next/image';
import Router from 'next/router';
import { FunctionComponent, memo } from 'react';

import useBreakpoints from '../Hooks/useBreakpoints';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button: FunctionComponent<DefaultComponentProps<any>> = props => {
  const { isDesktop } = useBreakpoints();
  return (
    <Grid
      container
      compoment={ButtonBase}
      onClick={() => props.href && Router.push(props.href)}
      {...props}
      style={{
        background: 'none',
        textDecoration: 'none',
        backgroundImage:
          'linear-gradient(white, white), radial-gradient(circle at 100%, var(--primary-blue) 0%, var(--primary-green) 100%)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        cursor: 'pointer',
      }}
      border={`double 4px transparent`}
      borderRadius={'4px'}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxHeight="40px"
    >
      <Grid
        item
        container
        padding={{ xs: '4px 8px' }}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        wrap="nowrap"
        gap={{ xs: '4px', lg: '8px' }}
      >
        <Grid
          item
          className={isDesktop ? 'barlow--size18 bold' : 'barlow--size12 bold'}
          width={'auto'}
        >
          {props.children}
        </Grid>
        <Grid item>
          <Image
            src={'/img/seta-right.webp'}
            alt={'seta'}
            width="6px"
            height={'12px'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Button);
